<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-select clearable v-model="search.schema" @change="Search" placeholder="终端appid">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
          <router-link :to="'/iot/client/add/'+0">
            <el-button type="primary" size="small">新建</el-button>
          </router-link>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="客户端授权编号">
        <template v-slot="scope">
          <span>{{ scope.row.client_id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="终端">
        <template v-slot="scope">
          <span>{{ appNameCode[scope.row.schema] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="授权类型">
        <template v-slot="scope">
          <span>{{ scope.row.grant_types }}</span>
        </template>
      </el-table-column>
      <el-table-column label="scope">
        <template v-slot="scope">
          <span>{{ scope.row.scope }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/client/add/'+scope.row.client_id">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteDelivery(scope.row.client_id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        schema: ''
      },
      list: [],
      total: 0,
      applist: [],
      appNameCode: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    Getschema() {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.appNameCode[item.code] = item.name
          })
          return
        }
        this.$message.error('获取app列表失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      this.api.ClientUserList({
        page: this.search.page,
        limit: this.search.limit,
        schema: this.search.schema,
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteDelivery(item) {
      this.$confirm('是否删除该设备出厂记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ClientUserDelete({client_id: item}).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.Getschema()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
